<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">
                    <ValidationProvider name="name" rules="required" v-slot="{ valid, errors }">
                        <b-input-group class="mb-3" prepend="TR">
                            <b-form-input v-model="form.name">
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"></b-form-invalid-feedback>
                    </ValidationProvider>
                    <ValidationProvider name="name_en" v-slot="{ valid, errors }">
                        <b-input-group prepend="EN">
                            <b-form-input v-model="form.name_en">
                            </b-form-input>
                        </b-input-group>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </ValidationProvider>
                </b-form-group>
                <div class="col-12">
                    <ValidationProvider name="code" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('code')">
                            <b-form-input v-model="form.code">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="rank" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('rank')">
                            <b-form-input v-model="form.rank">
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="data" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('data')">
                            <b-form-textarea rows="3" v-model="form.data">
                            </b-form-textarea>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <b-col cols="12" v-if="parameter.type == 'file'">
                    <ValidationProvider name="file" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('file')">
                            <div class="d-flex custom-file-upload">
                                <b-form-file v-model="form.file" :state="errors[0] ? false : (valid ? true : null)"
                                             :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')"
                                             ref="fileInput"></b-form-file>
                                <b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{
                                        $t('browse') }}</b-button>
                            </div>
                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <div class="col-12">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('available_roles')">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="form.available_roles"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('except_roles')">
                            <role-selectbox :valueType="'name'" :multiple="true" v-model="form.except_roles"
                                :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <b-form-group :label="$t('status')">
                        <b-form-select v-model="form.status">
                            <option :value="true">{{ $t('active') }}</option>
                            <option :value="false">{{ $t('passive') }}</option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </b-row>
        </ValidationObserver>

        <div class="col-12 mt-3 d-flex">
            <b-button @click="updateForm" :disabled="formLoading" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Other
import { ValidationProvider, ValidationObserver } from "vee-validate"
//Service
import ParameterItemService from "@/services/ParameterItemService";

export default {
    components: {
        ValidationProvider, ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        },
        parameter: {
            type: Object,
        }
    },
    data() {
        return {
            formLoading: false,
            form: {
                rank: null,
                data: null,
                code: null,
                name: null,
                name_en: null,
                available_roles: [],
                except_roles: [],
                status: true,
                file: null
            }
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            this.formLoading = true;
            ParameterItemService.getItem(id)
                .then((response) => {
                    this.form = response.data.data;
                    this.form.file = null
                    this.formLoading = false;
                })
                .catch((error) => {
                    if (error.data.message) {
                        this.$toast.error(this.$t("api." + error.data.message));
                    }
                }).finally(() => {
                    this.formLoading = false;
                });
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                let formData = new FormData()
                formData.append('rank',this.form.rank)
                formData.append('data',this.form.data)
                formData.append('code',this.form.code)
                formData.append('name',this.form.name)
                formData.append('name_en',this.form.name_en)
                formData.append('available_roles',this.form.available_roles)
                formData.append('except_roles',this.form.except_roles)
                formData.append('status',this.form.status)
                if(this.form.file){
                    formData.append('file',this.form.file)
                }

                ParameterItemService.updateItems(this.formId, formData)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("updateFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.formModalValidate)
                    }).finally(() => {
                        this.formLoading = false;
                    })
            }
        }
    }
}
</script>
