<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group :label="$t('name')">
                    <b-input-group prepend="TR" class="mb-2">
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                    <b-input-group prepend="EN">
                        <div class="label-as-input">{{ isNotNullValue(form.name_en) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('code')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('rank')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.rank) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('data')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.data) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" v-if="form.file">
                <b-form-group :label="$t('file')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.file.file_name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import ParameterItemService from "@/services/ParameterItemService";

    export default {
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                form: {
                    rank: null,
                    data: null,
                    code: null,
                    name: null,
                    name_en: null,
                }
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ParameterItemService.getItem(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                })
            },
        }
    }
</script>
